import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const KlarnaIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="24"
    viewBox="0 0 43 24"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <rect width="43.2" height="24" fill="#FFB3C7" rx="4.321" />
          <g fill="#0A0B09">
            <path
              d="M34.925 5.996c-.565 0-1.024.463-1.024 1.033 0 .57.459 1.033 1.024 1.033s1.023-.463 1.023-1.033c0-.57-.458-1.033-1.023-1.033zm-3.367-.798c0-.78-.662-1.414-1.478-1.414-.815 0-1.477.633-1.477 1.414 0 .78.661 1.414 1.477 1.414.816 0 1.478-.633 1.478-1.414zm.005-2.748h1.63v5.496h-1.63v-.352c-.46.317-1.016.504-1.616.504-1.587 0-2.874-1.299-2.874-2.9s1.287-2.9 2.874-2.9c.6 0 1.156.187 1.616.504V2.45zm-13.048.716V2.45h-1.67v5.496h1.674V5.38c0-.866.93-1.33 1.575-1.33h.019v-1.6c-.662 0-1.271.287-1.598.716zm-4.158 2.032c0-.78-.662-1.414-1.478-1.414s-1.477.633-1.477 1.414c0 .78.661 1.414 1.477 1.414.816 0 1.478-.633 1.478-1.414zm.005-2.748h1.63v5.496h-1.63v-.352c-.46.317-1.017.504-1.616.504-1.587 0-2.874-1.298-2.874-2.9 0-1.601 1.287-2.9 2.874-2.9.6 0 1.155.187 1.616.504V2.45zm9.814-.147c-.651 0-1.268.204-1.68.767v-.62h-1.623v5.496h1.643V5.058c0-.836.555-1.245 1.224-1.245.717 0 1.129.432 1.129 1.234v2.899h1.628V4.45c0-1.279-1.007-2.148-2.32-2.148zM7.497 7.946h1.707V.002H7.497v7.944zM0 7.948h1.807V0H0v7.948zM6.321 0c0 1.72-.665 3.321-1.849 4.511L6.97 7.948H4.738L2.024 4.213l.7-.53C3.886 2.805 4.553 1.463 4.553 0H6.32z"
              transform="translate(4.237 8.064)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

KlarnaIcon.propTypes = {
  className: PropTypes.string
};

export default KlarnaIcon;
