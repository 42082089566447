import { connect } from 'react-redux';
import { healthDataConsentActions } from '@ducks/healthDataConsent';
import HealthDataConsent from './HealthDataConsent';

const mapStateToProps = state => ({
  boilerplate: state.healthDataConsent.boilerplate,
  content: state.healthDataConsent.content,
  templateKey: state.healthDataConsent.templateKey,
  fulfilled: state.healthDataConsent.fulfilled
});

const mapDispatchToProps = dispatch => ({
  getHealthDataConsentTemplate: () => {
    dispatch(healthDataConsentActions.getHealthDataConsentTemplate());
  },
  acceptHealthDataConsent: templateKey => {
    dispatch(healthDataConsentActions.acceptHealthDataConsent(templateKey));
  },
  declineHealthDataConsent: templateKey => {
    dispatch(healthDataConsentActions.declineHealthDataConsent(templateKey));
  }
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthDataConsent);

export default container;
