import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const FacebookIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="1" fillRule="evenodd">
      <path
        d="M26.1428571,21.4112903 L26.1428571,25.1854839 L23.25,25.1854839 L23.25,29.9032258 L26.1428571,29.9032258 L26.1428571,45 L31.9285714,45 L31.9285714,29.9032258 L35.7857143,29.9032258 C36.1832254,30.3664961 36.5599539,28.0058146 36.75,25.1854839 L31.9285714,25.1854839 L31.9285714,22.3548387 C32.1838483,21.5530585 32.8363959,20.8778492 33.8571429,20.4677419 L36.75,20.4677419 L36.75,15.75 L31.9285714,15.75 C26.0199639,15.75 26.1679644,20.678858 26.1428571,21.4112903"
        id="Icon/Social/Facebook-white"
      />
      <path
        d="M30,60 C46.5685425,60 60,46.5685425 60,30 C60,13.4314575 46.5685425,0 30,0 C13.4314575,0 0,13.4314575 0,30 C0,46.5685425 13.4314575,60 30,60 Z M30,58.5 C14.2598846,58.5 1.5,45.7401154 1.5,30 C1.5,14.2598846 14.2598846,1.5 30,1.5 C45.7401154,1.5 58.5,14.2598846 58.5,30 C58.5,45.7401154 45.7401154,58.5 30,58.5 Z"
        id="Oval"
      />
    </g>
  </svg>
);

FacebookIcon.propTypes = {
  className: PropTypes.string
};

export default FacebookIcon;
