import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ArrowDownIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="8.45612"
      y1="13.0707"
      x2="8.45611"
      y2="1.5"
      stroke="black"
      strokeWidth="1.5"
    />
    <line
      x1="13.7575"
      y1="8.52211"
      x2="7.80714"
      y2="14.4725"
      stroke="black"
      strokeWidth="1.5"
    />
    <path d="M3 8.54964L8.95036 14.5" stroke="black" strokeWidth="1.5" />
  </svg>
);

ArrowDownIcon.propTypes = {
  className: PropTypes.string
};

export default ArrowDownIcon;
