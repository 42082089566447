/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import s from './Switch.scss';

const cx = classNames.bind(s);

class Switch extends Component {
  state = {
    activeClass: false
  };

  onTouchStart = () => {
    this.addActiveClassName();
  };

  onTouchMove = () => {
    this.removeActiveClassName();
  };

  onTouchEnd = () => {
    this.removeActiveClassName();
  };

  addActiveClassName = () => {
    this.setState({
      activeClass: true
    });
  };

  removeActiveClassName = () => {
    this.setState({
      activeClass: false
    });
  };

  render() {
    const {
      id,
      name,
      checked,
      disabled,
      label,
      onChange,
      containerClassName,
      size
    } = this.props;
    const { activeClass } = this.state;

    return (
      <div className={cx({ [containerClassName]: containerClassName })}>
        <input
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={cx({ input: true, input__disabled: disabled })}
          disabled={disabled}
        />
        <label
          htmlFor={id}
          className={cx({
            label: true,
            label__disabled: disabled,
            label__active: activeClass,
            label__checked: checked,
            label__small: size === 'small'
          })}
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
        >
          {label}
        </label>
      </div>
    );
  }
}

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default Switch;
