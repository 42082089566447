import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const CaretDownIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    viewBox="0 0 15 9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.434 0L0 1.434l6.42 6.42.717.716.716-.716 6.42-6.42L12.84 0 7.137 5.703z"
      fillRule="evenodd"
    />
  </svg>
);

CaretDownIcon.propTypes = {
  className: PropTypes.string
};

export default CaretDownIcon;
