import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const InstagramIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="1" fillRule="evenodd">
      <path
        d="M35.9090909,15 C41.0246364,15 45,18.9837684 45,24.0909091 L45,35.9090909 C45,41.0246364 41.0162316,45 35.9090909,45 L24.0909091,45 C18.9753636,45 15,41.0162316 15,35.9090909 L15,24.0909091 C15,18.9753636 18.9837684,15 24.0909091,15 L35.9090909,15 Z M36.5625,16.875 L23.4375,16.875 C19.9417487,16.875 16.875,19.9397449 16.875,23.4375 L16.875,36.5625 C16.875,40.0582513 19.9397449,43.125 23.4375,43.125 L36.5625,43.125 C40.0582513,43.125 43.125,40.0602551 43.125,36.5625 L43.125,23.4375 C43.125,19.9417487 40.0602551,16.875 36.5625,16.875 Z M30,23.4375 C33.6243687,23.4375 36.5625,26.3830062 36.5625,30 C36.5625,33.6243687 33.6169938,36.5625 30,36.5625 C26.3756313,36.5625 23.4375,33.6169938 23.4375,30 C23.4375,26.3756313 26.3830062,23.4375 30,23.4375 Z M29.5738636,25.3125 C27.4157846,25.3125 25.3125,27.4141532 25.3125,29.5738636 C25.3125,32.5842154 27.4141532,34.6875 29.5738636,34.6875 C32.5842154,34.6875 34.6875,32.5858468 34.6875,29.5738636 C34.6875,27.4157846 32.5858468,25.3125 29.5738636,25.3125 Z M38.4375,20.625 C39.4237228,20.625 40.2321062,21.3798721 40.3068588,22.3525344 L40.3125,22.5 C40.3125,23.4862228 39.5576279,24.2946062 38.5849656,24.3693588 L38.4375,24.375 C37.4019661,24.375 36.5625,23.5427535 36.5625,22.5 C36.5625,21.5137772 37.3173721,20.7053938 38.2900344,20.6306412 L38.4375,20.625 Z"
        id="Icon/Social/Instagram-white"
      />
      <path
        d="M30,60 C46.5685425,60 60,46.5685425 60,30 C60,13.4314575 46.5685425,0 30,0 C13.4314575,0 0,13.4314575 0,30 C0,46.5685425 13.4314575,60 30,60 Z M30,58.5 C14.2598846,58.5 1.5,45.7401154 1.5,30 C1.5,14.2598846 14.2598846,1.5 30,1.5 C45.7401154,1.5 58.5,14.2598846 58.5,30 C58.5,45.7401154 45.7401154,58.5 30,58.5 Z"
        id="Oval"
      />
    </g>
  </svg>
);

InstagramIcon.propTypes = {
  className: PropTypes.string
};

export default InstagramIcon;
