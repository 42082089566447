import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const DialogIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5666 4C19.5666 4 7.8881 4 5.43338 4C2.97865 4 1 5.97541 1 8.41473V14.6077C1 17.047 2.97865 19.0224 5.43338 19.0224H6.8467C6.96572 19.0224 7.06986 19.1271 7.06986 19.2469L7.01035 23.7813C7.01035 23.9759 7.23351 24.0657 7.3674 23.9459L12.8868 19.0823C12.8868 19.0823 12.9761 19.0224 13.0356 19.0224H19.5666C22.0213 19.0224 24 17.047 24 14.6077V8.41473C24 5.97541 22.0213 4 19.5666 4Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5 11H27.1449C29.2794 11 31 12.7675 31 14.95V20.5963C31 22.7789 29.2794 24.5463 27.1449 24.5463H26.8299C26.7264 24.5463 26.6358 24.6401 26.6358 24.7472L26.6876 28.8043C26.6876 28.9784 26.4935 29.0588 26.3771 28.9516L21.5776 24.5999C21.5776 24.5999 21.5 24.5463 20.5343 24.5463H16.8551C14.7206 24.5463 13 22.5 13.5 19.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

DialogIcon.propTypes = {
  className: PropTypes.string
};

export default DialogIcon;
