import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const NotFoundIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="nonzero">
      <path d="M43.588 34.537l20.365 20.365-9.051 9.05-20.365-20.364z" />
      <path d="M24 0c13.255 0 24 10.745 24 24S37.255 48 24 48 0 37.255 0 24 10.745 0 24 0zm0 5.818C13.958 5.818 5.818 13.958 5.818 24c0 10.042 8.14 18.182 18.182 18.182 10.042 0 18.182-8.14 18.182-18.182 0-10.042-8.14-18.182-18.182-18.182z" />
      <path d="M21.76 29.787V34H26.4v-4.213H21.76zM17 20.757h4.346c0-.492.059-.952.177-1.38.119-.43.3-.803.547-1.123a2.69 2.69 0 0 1 .946-.766c.385-.191.843-.287 1.375-.287.788 0 1.404.2 1.848.602.443.401.665 1.021.665 1.86.02.493-.074.903-.28 1.232-.208.328-.479.629-.814.903-.335.273-.7.547-1.094.82a7.247 7.247 0 0 0-1.123.972 5.619 5.619 0 0 0-.932 1.354c-.266.529-.428 1.185-.487 1.97v1.231h3.99v-1.04c.08-.547.272-1.003.577-1.368a5.68 5.68 0 0 1 1.05-.97 27.78 27.78 0 0 1 1.256-.85 5.97 5.97 0 0 0 1.212-1.025c.365-.401.67-.885.917-1.45.246-.566.37-1.286.37-2.162 0-.529-.124-1.099-.37-1.71-.246-.61-.655-1.18-1.227-1.71-.572-.528-1.325-.97-2.262-1.326-.936-.356-2.104-.534-3.503-.534-1.084 0-2.065.169-2.942.506a6.574 6.574 0 0 0-2.246 1.41 6.38 6.38 0 0 0-1.45 2.133c-.344.821-.526 1.724-.546 2.709z" />
    </g>
  </svg>
);

NotFoundIcon.propTypes = {
  className: PropTypes.string
};

export default NotFoundIcon;
