import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const LogbookIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M49.5362 14.4784H9.6664C5.43236 14.4784 2 17.9108 2 22.1448V48.5002C2 52.7343 5.43236 56.1666 9.6664 56.1666H49.5362C53.7702 56.1666 57.2026 52.7343 57.2026 48.5002V22.1448C57.2026 17.9108 53.7702 14.4784 49.5362 14.4784Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.0327 38.03L34.6473 31.7252C34.6677 31.6456 34.7073 31.5722 34.7629 31.5116L55.5133 8.82702C57.1819 6.85302 59.8235 6.54082 61.2517 7.59102C62.9163 8.81502 63.2709 12.138 60.9869 14.3006L40.3419 36.8782C40.2755 36.9508 40.1893 37.0022 40.0939 37.026L33.6389 38.6398C33.2711 38.7318 32.9387 38.3972 33.0327 38.03Z"
    />
  </svg>
);

LogbookIcon.propTypes = {
  className: PropTypes.string
};

export default LogbookIcon;
