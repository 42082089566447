import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const CaretRightIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    viewBox="0 0 9 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 13.566L1.434 15l6.42-6.42.716-.717-.716-.716-6.42-6.42L0 2.16l5.703 5.703z"
      fillRule="nonzero"
    />
  </svg>
);

CaretRightIcon.propTypes = {
  className: PropTypes.string
};

export default CaretRightIcon;
