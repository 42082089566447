import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const PlusIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path d="M0 11h24v2H0z" />
      <path d="M13 0v24h-2V0z" />
    </g>
  </svg>
);

PlusIcon.propTypes = {
  className: PropTypes.string
};

export default PlusIcon;
