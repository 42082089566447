/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import debounce from 'lodash/debounce';
import tracking from '@src/utils/helpers/tracking/tracking';

import ButtonPrimary from '@comp/Buttons/ButtonPrimary';
import ArrowDownIcon from '@comp/Icons/ArrowDownIcon';
import localize from '@src/i18n';
import s from './HealthDataConsent.scss';

const cx = classNames.bind(s);

const HealthDataConsent = ({
  getHealthDataConsentTemplate,
  acceptHealthDataConsent,
  declineHealthDataConsent,
  boilerplate,
  content,
  templateKey,
  fulfilled
}) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollableElement = useRef();

  useEffect(() => {
    getHealthDataConsentTemplate();
    tracking.trackHealthConsentForProductImprovementPageView();
  }, []);

  useEffect(() => {
    if (fulfilled) {
      handlScrollPosition();
    }
  }, [fulfilled]);

  const handlScrollPositionDebounced = debounce(() => {
    handlScrollPosition();
  }, 50);

  const handlScrollPosition = () => {
    if (scrollableElement.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollableElement.current;
      if (Math.round(scrollTop) + clientHeight === scrollHeight) {
        setScrolledToBottom(true);
      }
    }
  };

  const onScroll = () => {
    handlScrollPositionDebounced();
  };

  const scrollToFooter = () => {
    setScrolledToBottom(true);
    setImmediate(() => {
      document
        .getElementById('consent-content')
        .getElementsByTagName('footer')[0]
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  };

  return (
    <>
      <div
        id="consent-content"
        dangerouslySetInnerHTML={{ __html: boilerplate }}
        className={s.container}
        ref={scrollableElement}
        onScroll={() => (!scrolledToBottom ? onScroll() : null)}
      />
      <div
        className={cx({
          footerContainer: true,
          footerContainer__topShadow: !scrolledToBottom
        })}
      >
        {!scrolledToBottom && (
          <ButtonPrimary
            text={localize('button_title_hdcpi_read_more')}
            onClick={scrollToFooter}
            outline
            size="large"
            icon={<ArrowDownIcon className={s.icon} />}
            className={s.buttonReadMore}
          />
        )}
        {scrolledToBottom && (
          <>
            <p>{content}</p>
            <ButtonPrimary
              text={localize(
                'product_improvement_consent_button_title_decline'
              )}
              onClick={() => declineHealthDataConsent(templateKey)}
              outline
              size="large"
              className={s.buttonDecline}
            />
            <ButtonPrimary
              text={localize(
                'product_improvement_consent_button_title_approve'
              )}
              onClick={() => acceptHealthDataConsent(templateKey)}
              size="large"
              className={s.buttonAccept}
            />
          </>
        )}
      </div>
    </>
  );
};

HealthDataConsent.propTypes = {
  getHealthDataConsentTemplate: PropTypes.func,
  acceptHealthDataConsent: PropTypes.func,
  declineHealthDataConsent: PropTypes.func,
  boilerplate: PropTypes.string,
  content: PropTypes.string,
  templateKey: PropTypes.shape({
    id: PropTypes.string,
    version: PropTypes.number
  }),
  fulfilled: PropTypes.bool
};

export default HealthDataConsent;
