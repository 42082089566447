import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const BowlAndSticksWithCircleIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    width="111"
    height="114"
    viewBox="0 0 111 118"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M55.5 0c31.727 0 57.447 26.415 57.447 59s-25.72 59-57.447 59C23.773 118-1.947 91.585-1.947 59S23.773 0 55.5 0zm0 4C25.924 4 1.947 28.624 1.947 59s23.977 55 53.553 55c29.576 0 53.553-24.624 53.553-55S85.076 4 55.5 4zM36.97 18.568a1.924 1.924 0 0 1 2.373 1.33L49.503 56H92.5v1.812c0 .326-.018.902-.072 1.692a48.304 48.304 0 0 1-.495 4.29c-.706 4.431-1.978 8.864-3.965 13.02C82.249 88.773 71.882 96 55.987 96c-15.896 0-26.262-7.227-31.981-19.187-1.987-4.155-3.259-8.588-3.965-13.018a48.304 48.304 0 0 1-.495-4.29 33.956 33.956 0 0 1-.071-1.56l-.001-.128 1.725 1.806-1.725-1.811V56H37.78L23.247 26.55a2 2 0 0 1 .761-2.598l.137-.075.062-.03c.963-.44 2.1-.016 2.54.948L40.997 56h5.592l-10.97-34.93c-.313-.995.2-2.052 1.15-2.433l.147-.052.055-.017zm2.598 41.055H23.015c.085 1.09.224 2.29.429 3.573.652 4.096 1.827 8.188 3.645 11.99 5.152 10.776 14.354 17.19 28.898 17.19s23.746-6.414 28.898-17.19c1.818-3.802 2.992-7.894 3.645-11.99.205-1.283.344-2.483.429-3.573H50.524l3.939 13.994a1.135 1.135 0 0 1-.639 1.347l-.113.043a1.101 1.101 0 0 1-1.38-.72l-4.606-14.664H42.65l7.207 15.784a1.145 1.145 0 0 1-.423 1.438l-.106.06a1.098 1.098 0 0 1-1.476-.495l-8.284-16.787z"
    />
  </svg>
);

BowlAndSticksWithCircleIcon.propTypes = {
  className: PropTypes.string
};

export default BowlAndSticksWithCircleIcon;
