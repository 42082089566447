import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const FoodIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5429_5483)">
      <path
        d="M4.17011 11.2998H28.6001C28.7701 11.2998 28.9001 11.4398 28.9001 11.5998V24.4298C28.9001 26.3998 27.3001 27.9998 25.3301 27.9998H7.43011C5.46011 27.9998 3.86011 26.3998 3.86011 24.4298V11.5998C3.86011 11.4298 4.00011 11.2998 4.16011 11.2998H4.17011Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6301 11.3002L25.4201 3.49018C26.0901 2.68018 27.2401 2.63017 27.9401 3.36018C28.6701 4.11018 28.6801 5.42018 27.9401 6.21018C26.4201 7.91018 24.9001 9.61018 23.3701 11.3102H18.6201L18.6301 11.3002Z"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M1.36011 15.0605H3.87011"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.4402 14.9902H31.6402"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5429_5483">
        <rect width="32" height="32" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

FoodIcon.propTypes = {
  className: PropTypes.string
};

export default FoodIcon;
