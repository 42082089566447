import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import s from './Textarea.scss';

const cx = classNames.bind(s);

const Textarea = ({
  id,
  name,
  type,
  expand,
  className,
  meta,
  input,
  autoComplete,
  placeholder
}) => (
  <textarea
    id={id}
    name={name}
    type={type}
    className={cx({
      textarea: true,
      textarea__expand: expand,
      textarea__error: meta && !!meta.error,
      [className]: className
    })}
    autoComplete={autoComplete}
    placeholder={placeholder}
    {...input}
  />
);

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  expand: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string
  }),
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.shape({})
};

export default Textarea;
