import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const CrossIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.816 1.4L12.434 0 6.908 5.6 1.382 0 0 1.4 5.526 7 0 12.6 1.382 14l5.526-5.6 5.526 5.6 1.382-1.4L8.289 7z"
      fillRule="evenodd"
    />
  </svg>
);

CrossIcon.propTypes = {
  className: PropTypes.string
};

export default CrossIcon;
